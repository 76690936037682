import { createGlobalStyle } from "styled-components"

export const GlobalCSS = createGlobalStyle`

    :root {
        --ff-text: 'Poppins', sans-serif;
        --ff-heading: 'Poppins', sans-serif;

    }

    html {
        font-size: 93.75%;

        @media (min-width: 45em){
            font-size: 100%;
        }
    }



    body {
        font-family: var(--ff-text);
        background-color: #22222e;
        color: ${({ theme }) => theme.white};
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: var(--ff-heading);
    }
`
