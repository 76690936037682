import { Route, Routes } from "react-router-dom"
import { Nomenclaturas, Login, UTMCopy, UTMMidia } from "./pages"
import { ProtectedRoute } from "./components/ProtectedRoute"
import { useAuth } from "./hooks/useAuth"

import { DataContextProvider } from "./contexts/DataContext"

export function AppRoutes() {
   const { authenticated } = useAuth()

   return (
      <Routes>
         <Route path="/login" element={<Login />} />

         <Route
            path="/"
            element={
               <ProtectedRoute authenticated={authenticated} redirectPath="/login">
                  <DataContextProvider>
                     <Nomenclaturas />
                  </DataContextProvider>
               </ProtectedRoute>
            }
         />

         <Route
            path="/utm-copy"
            element={
               <ProtectedRoute authenticated={authenticated} redirectPath="/login?redirect=utm-copy">
                  <DataContextProvider>
                     <UTMCopy />
                  </DataContextProvider>
               </ProtectedRoute>
            }
         />

         <Route
            path="/utm-midia"
            element={
               <ProtectedRoute authenticated={authenticated} redirectPath="/login?redirect=utm-midia">
                  <DataContextProvider>
                     <UTMMidia />
                  </DataContextProvider>
               </ProtectedRoute>
            }
         />
      </Routes>
   )
}
