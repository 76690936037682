export const etapa_funil = ["tofu_mr", "tofu_aw", "mofu_aw", "bofu_hr", "mofu_mr", "bofu_mr", "bofu_aw"]

export const utm_source = [
   "email",
   "hiperlink_blog",
   "banner_blog",
   "banner_site",
   "linkedin",
   "evento",
   "facebook",
   "material_rico",
   "newsletter",
   "landing_page",
   "landing_page_agradecimento",
   "pillar_page",
   "instagram",
   "instagram_bio",
   "instagram_story",
   "twitter",
   "video",
   "whatsapp",
]

export const utm_term = [
   "analise_especialista",
   "calculadora",
   "case",
   "checklist",
   "ebook",
   "expert_review",
   "framework",
   "guia",
   "infografico",
   "kit",
   "leadership_guide",
   "one_page",
   "planilha",
   "quadro_comparativo",
   "quiz",
   "relatorio",
   "slideshare",
   "webinar",
   "whitepaper",
]

export const utm_source_relations = {
   hiperlink_blog: "organic",
   banner_blog: "organic",
   banner_site: "site",
   linkedin: "social",
   evento: "",
   facebook: "social",
   material_rico: "organic",
   pillar_page: "site",
   email: "email",
   newsletter: "email",
   landing_page: "site",
   landing_page_agradecimento: "site",
   instagram: "social",
   instagram_bio: "social",
   instagram_story: "social",
   twitter: "social",
   video: "",
   whatsapp: "social",
}
