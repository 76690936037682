import React, { useEffect, useState } from "react"
import { ButtonChangeView } from "../../components/ButtonChangeView"
import * as S from "./style"
import { links } from "../../common/links"
import { Layout } from "../../components/Layout"
import { Loading } from "../../components/Loading"
import { useData } from "../../hooks/useData"
import { menus } from "./common/menu"

import { Menu } from "../../components/pages/Nomenclaturas/Menu"
import { NowDisplaying } from "../../components/pages/Nomenclaturas/NowDisplaying"

export const Nomenclaturas = () => {
   const DISPLAY_SETTING_NAME = "NOMEN_CURRENT_DISPLAY"

   const [display, setDisplay] = useState("runrunit")
   const { loading } = useData()

   function handleClick(value) {
      setDisplay(value)
      localStorage.setItem(DISPLAY_SETTING_NAME, JSON.stringify(value))
   }

   useEffect(() => {
      const savedDisplaySetting = JSON.parse(localStorage.getItem(DISPLAY_SETTING_NAME))

      if (savedDisplaySetting) {
         setDisplay(savedDisplaySetting)
      }
   }, [])

   if (loading) {
      return <Loading />
   }

   return (
      <Layout>
         <S.Container className="fade">
            <S.ButtonsWrapper>
               {links.map((link) => (
                  <ButtonChangeView
                     active={display === link.path ? true : false}
                     key={link.label}
                     onClick={(e) => {
                        e.stopPropagation()
                        handleClick(link.path)
                     }}
                  >
                     {link.label}
                  </ButtonChangeView>
               ))}
            </S.ButtonsWrapper>

            <S.GridLayout className="fade-left">
               <Menu menus={menus} display={display} />
               <NowDisplaying display={display} />
            </S.GridLayout>
         </S.Container>
      </Layout>
   )
}
