import styled from "styled-components"
import { DefaultContainer } from "../DefaultContainer"

export const Wrapper = styled.div`
   display: grid;
   background-color: ${(props) => props.theme.black};
   color: ${(props) => props.theme.white};
   box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
`

export const Container = styled(DefaultContainer)`
   display: grid;
   justify-items: center;
   align-items: center;
   position: relative;
   gap: 2rem;
   flex-wrap: wrap;

   padding-block: 0.75rem;

   > a {
      max-width: 6rem;
   }

   > img {
      max-width: 6rem;
   }

   @media (min-width: 62.5rem) {
      grid-template-columns: min-content 1fr max-content;
   }
`

export const Navigation = styled.nav`
   display: flex;
   gap: 2rem;
   font-weight: 600;
   max-width: max-content;
   margin-left: 0;
   font-size: 0.95rem;

   a {
      position: relative;
      opacity: 0.85;
      transition: opacity 100ms ease;

      &::after {
         content: "";
         background-color: ${(props) => props.theme.red};
         width: 100%;
         height: 2px;
         position: absolute;
         bottom: -2px;
         border-radius: 100vmax;
         left: 0;
         transform: scale(0);
         transform-origin: center;
         transition: transform 150ms ease-in;
      }

      &:hover {
         opacity: 1;
      }

      &:hover::after {
         transform: scale(1);
      }
   }
`
