import styled from "styled-components"

export const Container = styled.label`
   font-weight: 600;
   font-size: 0.95rem;
   display: grid;
   grid-template-rows: 1fr max-content;

   > * {
      margin-top: 0.75rem;
   }

   input,
   select {
      padding: 0 0.75em;
      font-size: 1rem;
      max-width: 100%;
      height: 3rem;
      line-height: 1.5;
      font-weight: 500;
      display: block;
      width: 100%;

      outline: none;
      border: none;

      border-radius: ${(props) => props.theme.border.radius};
      overflow: hidden;
      background-color: ${(props) => props.theme.white};

      &:focus {
         outline: 2px solid #ccc;
         outline-offset: 2px;
      }
   }

   input::placeholder {
      font-weight: 400;
   }
`
