function utmCopyInputValidation(event) {
   const input = event.target
   const valorAntigo = input.value
   const valorNovo = valorAntigo.replace(/[^a-zA-Z0-9\-._:/]/g, "")
   if (valorNovo !== valorAntigo) {
      input.value = valorNovo
   }
}

function utmMediaInputValidation(event) {
   const input = event.target
   const valorAntigo = input.value
   const valorNovo = valorAntigo.replace(/[^a-zA-Z0-9\-._:/#]/g, "")
   if (valorNovo !== valorAntigo) {
      input.value = valorNovo
   }
}

export { utmCopyInputValidation, utmMediaInputValidation }
