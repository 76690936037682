import styled from "styled-components"

export const Container = styled.div`
   padding-block: 1rem 2rem;

   h3 {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-wrap: wrap;
      margin-bottom: 1rem;

      svg {
         color: ${(props) => props.theme.red};
         font-size: 1.5rem;
      }
   }

   ul {
      display: grid;
      gap: 0.5rem;

      li {
         display: flex;
         align-items: center;
         gap: 0.5rem;
         font-weight: 600;
         animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;

         &::before {
            content: "";
            flex-shrink: 0;
            width: 7px;
            aspect-ratio: 1;
            background-color: ${(props) => props.theme.red};
            border-radius: 2px;
            position: relative;
            top: -1px;
         }
      }
   }

   @keyframes shake {
      10%,
      90% {
         transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
         transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
         transform: translate3d(-2px, 0, 0);
      }

      40%,
      60% {
         transform: translate3d(2px, 0, 0);
      }
   }
`
