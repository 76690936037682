import styled from "styled-components"
import { DefaultContainer } from "../../components/DefaultContainer"

export const Container = styled(DefaultContainer)`
   h1 {
      text-align: center;
      font-weight: 400;
   }
`

export const ButtonsWrapper = styled.div`
   margin-block: 3.75rem 5rem;

   display: grid;
   gap: 1rem;

   @media (min-width: 45em) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 3rem;
   }
`

export const GridLayout = styled.div`
   @media (min-width: 1200px) {
      display: grid;
      grid-template-columns: 14rem 1fr;
      align-items: start;
      gap: 3rem;
   }
`
