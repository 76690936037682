export const funil = ["bofu_hr", "mofu_mr", "bofu_hr"]

export const produto = ["produtoa", "produtob"]

export const objetivo = [
   { label: "Sponsored Content", value: "sponsored_content" },
   { label: "Text ads", value: "text_ads" },
   { label: "Text Content", value: "text_content" },
   { label: "Vídeo", value: "video" },
   { label: "InMail", value: "inmail" },
   { label: "Conversa", value: "conversa" },
   { label: "Carrossel", value: "carrossel" },
   { label: "Lead Ads", value: "lead_ads" },
]
