import styled, { css } from "styled-components"
import { DefaultContainer } from "../../../components/DefaultContainer"

export const Container = styled(DefaultContainer)`
   padding-block: 4rem;

   form {
      margin-top: 4rem;
   }
`

export const ResultBox = styled.div`
   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
   margin-block: 3rem 2rem;
   background-color: ${({ theme }) => theme.white};
   color: ${({ theme }) => theme.black};

   display: flex;
   justify-content: space-between;

   border-radius: 6px;
   min-height: 3rem;
   position: relative;
   overflow: hidden;

   p,
   h6 {
      word-wrap: break-word;
      word-break: break-all;
      padding: 0.5rem 1em;

      font-weight: 700;
      font-size: 1rem;
      display: grid;
      place-items: center;
   }
`

export const LittleBox = styled.div`
   width: 30px;
   height: 15px;
   background-color: ${(props) => (props.bg ? props.bg : "#000000")};
`

export const ButtonCopyTextWrapper = styled.div`
   position: absolute;
   top: 0.6em;
   right: 0.75em;

   button {
      border-radius: 5px;
      padding-block: 0.15rem;
      min-width: 2.25rem;
      font-size: 1.25rem;
   }
`

export const CustomUTMMidiaField = styled.div`
   display: grid;
   background-color: ${(props) => (props.bg ? props.bg : "#000000")};
   padding: 0.75em;
   border-radius: 10px;
   position: relative;

   span {
      padding-right: 2.5rem;
      display: block;
      margin-top: 0;
   }

   input {
      margin-top: 0.75em;
   }

   ${(props) =>
      props.span === "true" &&
      css`
         @media (min-width: 45em) {
            grid-column: span 2;
         }
      `}

   ${(props) =>
      props.copy === "true" &&
      css`
         grid-template-columns: 1fr max-content;
         gap: 0.75em;

         button {
            border-radius: 8px;
         }
      `}
`

export const CustomTitleDivisor = styled.div`
   margin-block: 5rem 4rem;
   text-align: center;
   display: grid;
   align-items: center;
   justify-items: center;
   position: relative;

   &::after {
      content: "";
      width: 40%;
      height: 2px;
      background-color: ${(props) => props.theme.red};
      position: relative;
      display: block;
      margin-top: 1rem;
      box-shadow: rgb(0 0 0 / 45%) 0px 25px 20px -20px;
   }

   > * {
      font-size: 1.35rem;
      font-weight: 700;
   }
`
