import { useState } from "react"
import { ValidateErrorsBox } from "../../../../../components/ValidateErrorsBox"
import { useValidateLetters } from "../../../../../hooks/useValidateLetters"
import { inputValidity } from "../../../helpers/inputValidity"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import FieldGroup from "../../../../../components/FieldGroup"

const NomenclaturaLPeTP = ({ data }) => {
   const dataToDisplay = {
      _bowe: "",
      _tipoPagina: data.plataformaAutomacao.tipoPagina,
      _etapaFunil: data.plataformaAutomacao.etapaFunilPlataformas,
      _nomeProduto: data.geral[0].produtos,
      _tipoMaterial: data.plataformaAutomacao.tipoDoMaterial,
      _slug: "",
      _regiao: data.plataformaAutomacao.regiao || [],
   }

   const { errorsList, validateLetters, blockSpecialChars, specialSlugValidation } = useValidateLetters()

   const [bowe, setBowe] = useState("bw")
   const [tipoPagina, setTipoPagina] = useState(dataToDisplay._tipoPagina[0])
   const [etapaFunil, setEtapaFunil] = useState(dataToDisplay._etapaFunil[0])
   const [nomeProduto, setNomeProduto] = useState("")
   const [tipoMaterial, setTipoMaterial] = useState(dataToDisplay._tipoMaterial[0])
   const [slug, setSlug] = useState(dataToDisplay._slug)
   const [regiao, setRegiao] = useState("")

   return (
      <section id="lp-tp">
         <SectionTitle>Nomenclaturas para LP e TP</SectionTitle>

         {errorsList.length > 0 && <ValidateErrorsBox list={errorsList} />}

         <Form>
            <FieldGroup
               as="input"
               label="bw (bowe) *"
               onChange={({ target }) => setBowe(target.value)}
               value={bowe}
               onBlur={inputValidity}
            />

            <FieldGroup
               as="select"
               label="LP ou TP *"
               onChange={({ target }) => setTipoPagina(target.value)}
               value={tipoPagina}
               options={dataToDisplay._tipoPagina}
            />

            <FieldGroup
               as="select"
               label="Etapa do Funil"
               onChange={({ target }) => setEtapaFunil(target.value)}
               value={etapaFunil}
               options={dataToDisplay._etapaFunil}
               emptyOption="N/A"
            />

            <FieldGroup
               as="input"
               label="Nome do Produto *"
               onChange={({ target }) => setNomeProduto(target.value)}
               value={nomeProduto}
               onKeyDown={(e) => {
                  blockSpecialChars(e)
               }}
               onBlur={(e) => validateLetters(e, setNomeProduto, "Nome do produto *")}
            />

            <FieldGroup
               as="select"
               label="Tipo do Material"
               onChange={({ target }) => setTipoMaterial(target.value)}
               value={tipoMaterial}
               options={dataToDisplay._tipoMaterial}
               emptyOption="N/A"
            />

            <FieldGroup
               as="input"
               label="Slug *"
               onChange={({ target }) => setSlug(target.value)}
               value={slug}
               onKeyDown={(e) => {
                  specialSlugValidation(e)
               }}
               onBlur={(e) => validateLetters(e, setSlug, "Slug *", true)}
            />

            <FieldGroup
               as="select"
               label="Região"
               onChange={({ target }) => setRegiao(target.value)}
               value={regiao}
               options={dataToDisplay._regiao}
               emptyOption="N/A"
            />
         </Form>

         <ResultLabel disabled={errorsList.length > 0}>
            {`${bowe}_${tipoPagina}_${etapaFunil ? `${etapaFunil}_` : ""}${nomeProduto}_${tipoMaterial}_${slug}${
               regiao ? `_${regiao}` : ""
            }`}
         </ResultLabel>
      </section>
   )
}

export default NomenclaturaLPeTP
