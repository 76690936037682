import React from "react"
import { Container } from "./styles"
import { BsExclamationSquare } from "react-icons/bs"

export const ValidateErrorsBox = ({ list }) => {
   return (
      <Container>
         {list.length > 0 && (
            <>
               <h3>
                  <BsExclamationSquare />
                  Atenção, corrija os erros nos seguintes campos:
               </h3>
               <ul>
                  {list.map((item) => (
                     <li key={item}>{item}</li>
                  ))}
               </ul>
            </>
         )}
      </Container>
   )
}
