import React from "react"
import { Container } from "./styles"

const FieldGroup = ({ as, label, onChange, value, emptyOption, options, type, span, ...props }) => {
   const As = as

   if (As === "select") {
      return (
         <Container className={span ? "span-2" : ""}>
            <span>{label}</span>
            <select onChange={onChange} value={value} {...props}>
               {emptyOption && <option value="">{emptyOption}</option>}
               {options.map((option, i) => (
                  <option key={i} value={option}>
                     {option}
                  </option>
               ))}
            </select>
         </Container>
      )
   }

   if (As === "input") {
      return (
         <Container className={span ? "span-2" : ""}>
            <span>{label}</span>
            <input type={type || "text"} value={value} onChange={onChange} {...props} />
         </Container>
      )
   }
}

export default FieldGroup
