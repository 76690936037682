import React, { useState } from "react"
import { Container } from "./style"
import { useAuth } from "../../hooks/useAuth"
import { FcGoogle } from "react-icons/fc"

export const LoginModal = () => {
   const { handleLogin } = useAuth()
   const [email, setEmail] = useState("")

   function handleSubmit(e) {
      e.preventDefault()

      if (!email.trim() === "") {
         return
      }

      if (email !== "gerador@bowe.co") {
         e.preventDefault()
         return
      }

      handleLogin(email)
   }

   return (
      <Container>
         <div>
            <img aria-hidden="true" src="/images/logo.png" alt="" />
            <p>Entre com sua conta bowe.co</p>
            <button onClick={handleLogin}>
               <div>
                  <FcGoogle />
               </div>
               Continuar com Google
            </button>
            <br />
            ou
            <br />
            <form onSubmit={handleSubmit}>
               <input
                  type="text"
                  placeholder="Email de uso único"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
               />
               <button type="submit">Entrar</button>
            </form>
         </div>
      </Container>
   )
}
