export const menus = {
   "plataformas-automacao": [
      { title: "Fluxos automação", id: "fluxos-automacao" },
      { title: "Formulário LP e Site", id: "form-lp-site" },
      { title: "Lead ads e lead gen", id: "lead-ads-gen" },
      { title: "LP e TP", id: "lp-tp" },
      { title: "Título email", id: "titulo-email" },
   ],
   runrunit: [
      { title: "Nome da Subtarefa", id: "nome-subtarefa" },
      { title: "Nome da Tarefa", id: "nome-tarefa" },
      { title: "Nome do Projeto", id: "nome-projeto" },
      { title: "Subgrupo de Projeto", id: "subgrupo-projeto" },
      { title: "Grupo de Projeto", id: "grupo-projeto" },
   ],
   "arquivos-drive": [
      { title: "ABM", id: "abm" },
      { title: "Copy", id: "copy" },
      { title: "DA/Motion", id: "da-motion" },
      { title: "Digital/Mídia", id: "digital-midia" },
      { title: "Financeiro", id: "financeiro" },
      { title: "IC", id: "ic" },
      { title: "Planejamento", id: "planejamento" },
   ],
}
