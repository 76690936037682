export const theme = {
   darkBlue: "#222137",
   red: "#B52041",
   blue: "#2E2C67",
   white: "#f8f7f4",
   black: "#202020",
   purple: "#584AD4",
   green: "#20CE89",
   border: {
      radius: "8px",
   },
}
