import { useData } from "../../../../hooks/useData"
import { Container } from "./style"
import FluxosAutomacao from "./FluxosAutomacao/index"
import NomenclaturaLPeTP from "./NomenclaturaLPeTP/index"
import TituloEmail from "./TituloEmail/index"
import NomeFormSiteLP from "./NomeFormSiteLP"
import NomeFormLeadAds from "./NomeFormLeadAds"

export function PlataformaAutomacaoContainer() {
   const { data } = useData()

   return (
      <Container>
         <FluxosAutomacao data={data} />
         <NomeFormSiteLP data={data} />
         <NomeFormLeadAds data={data} />
         <NomenclaturaLPeTP data={data} />
         <TituloEmail data={data} />
      </Container>
   )
}
