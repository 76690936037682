import request from "graphql-request"

async function api_fetch() {
   try {
      const { padroesGerais } = await request(
         process.env.REACT_APP_API_KEY,
         `
        query FETCH_ALL_DATA{
            padroesGerais{
              geral,
              drive,
              plataformaAutomacao,
              runrunit,
              criativos,
              entregaveis
            }
          }
            `
      )

      if (padroesGerais) {
         return padroesGerais[0]
      } else {
         return
      }
   } catch (err) {
      throw new Error("Something went wrong...")
   }
}

export { api_fetch }
