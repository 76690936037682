import { useState } from "react"

export const useValidateLetters = () => {
   const [isErrorController, setIsErrorController] = useState(false)
   const [errorsList, setErrorsList] = useState([])

   const validateLetters = (e, setState, fieldName = "Indefinido", slug = false) => {
      let regex = /^[\w]+$/

      if (slug === true) {
         regex = /^[\w-]+$/
      }

      const str = e.target.value

      if (e.target.value === "") {
         e.target.style.border = "none"
         const deletedItem = errorsList.find((name) => String(name) === String(fieldName))
         const index = errorsList.indexOf(deletedItem)
         if (index > -1) {
            errorsList.splice(index, 1)

            const newArray = errorsList || []
            setIsErrorController(!isErrorController)
            setErrorsList(newArray)
         }

         return
      }

      if (setState) {
         setState((state) => state.toLowerCase())
      }

      if (!regex.test(str)) {
         const isErrorExist = errorsList.find((err) => err === fieldName)
         if (isErrorExist) return

         e.target.style.border = "2px solid #b52041"

         setErrorsList([...errorsList, fieldName])
         setIsErrorController(!isErrorController)
      } else {
         e.target.style.border = "none"
         const deletedItem = errorsList.find((name) => String(name) === String(fieldName))
         const index = errorsList.indexOf(deletedItem)
         if (index > -1) {
            errorsList.splice(index, 1)

            const newArray = errorsList || []
            setIsErrorController(!isErrorController)
            setErrorsList(newArray)
         }
      }
   }

   const blockSpecialChars = (e) => {
      const key = e.key

      const invalidKeys = [
         "-",
         " ",
         "^",
         "~",
         ";",
         ",",
         "!",
         "'",
         "@",
         "#",
         "$",
         "%",
         "¨",
         "&",
         "*",
         "(",
         ")",
         "+",
         "=",
         "?",
         "/",
         "|",
      ]

      const isInvalid = invalidKeys.find((x) => String(x) === String(key))

      if (isInvalid || key === "Space") {
         e.preventDefault()
      }
   }

   const specialSlugValidation = (e) => {
      const key = e.key

      const invalidKeys = [
         " ",
         "^",
         "~",
         ";",
         ",",
         "!",
         "'",
         "@",
         "#",
         "$",
         "%",
         "¨",
         "&",
         "*",
         "(",
         ")",
         "+",
         "=",
         "?",
         "/",
         "|",
      ]

      const isInvalid = invalidKeys.find((x) => String(x) === String(key))

      if (isInvalid || key === "Space") {
         e.preventDefault()
      }
   }

   return { errorsList, validateLetters, blockSpecialChars, specialSlugValidation }
}
