import { useEffect, useState } from "react"
import { inputValidity } from "../../../helpers/inputValidity"
import { useData } from "../../../../../hooks/useData"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import FieldGroup from "../../../../../components/FieldGroup"

const MR = {
   tamanhos: ["P", "M", "G"],
   tipos: ["E-book", "Infográfico", "OnePage", "Outros", "Planilha", "Quiz", "Slide-share", "Webinar", "Whitepaper"],
   etapa: ["ToFu", "BoFu", "MoFu"],
}

const BI_INCONSISTENCIA_DADOS_OPTIONS = [
   "Dados existem nas plataformas de marketing e não estão na dash",
   "Dados que estão no gerenciador não estão de acordo com o que está apresentado na dash",
   "Dados existem no CRM e não estão na dash",
   "Dados de metas estão na planilha de metas e não estão na dash",
   "Outros",
]

const BI_INCONSISTENCIA_DADOS = "Inconsistência de dados"

const QA_FUNCOES = ["n/d", "DEV", "DA", "MKT OPS", "RED", "MOT"]

const Subtarefa = () => {
   const { data } = useData()
   const ENTREGAVEIS_GERAL = data.entregaveis
   const ajustesRefação = ["Não se aplica", "Ajustes BW", "Ajustes CL", "Melhoria BW", "Melhoria CL"]

   const [entregavel, setEntregavel] = useState("")
   const [entregaveis, setEntregaveis] = useState([])
   const [funcaoResponsavel, setFuncaoResponsavel] = useState(data.runrunit.funcaoResponsavel[0])
   const [nomeMaterial, setNomeMaterial] = useState("")
   const [ajustes, setAjustes] = useState(ajustesRefação[0])
   const [id, setId] = useState("")
   const [isRefactorOn, setIsRefactorOn] = useState(true)

   const [incosistenciaDados, setIncosistenciaDados] = useState(BI_INCONSISTENCIA_DADOS_OPTIONS[0])

   const [MRtamanho, setMRtamanho] = useState(MR.tamanhos[0])
   const [MRtipo, setMRtipo] = useState(MR.tipos[0])
   const [MRetapa, setMRetapa] = useState(MR.etapa[0])

   const [qaFuncao, setQaFuncao] = useState(QA_FUNCOES[0])

   useEffect(() => {
      if (ajustes === "Não se aplica" || ajustes === "") {
         setIsRefactorOn(false)
      } else {
         setIsRefactorOn(true)
      }
   }, [ajustes])

   useEffect(() => {
      const toRender = ENTREGAVEIS_GERAL[funcaoResponsavel.toLowerCase()]

      setEntregaveis(toRender)
      setEntregavel(toRender[0])

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [funcaoResponsavel])

   return (
      <section id="nome-subtarefa">
         <SectionTitle>Nome da Subtarefa</SectionTitle>

         {isRefactorOn && (
            <p style={{ marginBottom: "1rem" }}>
               <span style={{ fontWeight: "700", color: "#b52041", fontSize: "1.5rem" }}>-</span>{" "}
               <strong>Campo de ID</strong>: caso seja refação em mais de uma task, separar os ID's por ponto, ex.:
               <strong> 12345.2468</strong>
            </p>
         )}

         <Form>
            <FieldGroup
               as="select"
               label="Refação (Ajuste: correção de falha) *"
               options={ajustesRefação}
               onChange={({ target }) => setAjustes(target.value)}
               value={ajustes}
            />

            <FieldGroup
               as="select"
               label="Função Responsável *"
               options={data.runrunit.funcaoResponsavel}
               onChange={({ target }) => setFuncaoResponsavel(target.value)}
               value={funcaoResponsavel}
            />

            {funcaoResponsavel.toLowerCase() === "qa" && (
               <FieldGroup
                  as="select"
                  label="Função (QA) "
                  options={QA_FUNCOES}
                  onChange={({ target }) => setQaFuncao(target.value)}
                  value={qaFuncao}
               />
            )}

            {entregaveis && (
               <FieldGroup
                  as="select"
                  label="Entregável *"
                  onChange={({ target }) => setEntregavel(target.value)}
                  value={entregavel}
                  options={entregaveis}
               />
            )}

            {/* MR */}
            {entregavel === "MR" && (
               <>
                  <FieldGroup
                     as="select"
                     label="Tamanho do MR *"
                     options={MR.tamanhos}
                     value={MRtamanho}
                     onChange={({ target }) => setMRtamanho(target.value)}
                  />
                  <FieldGroup
                     as="select"
                     label="Tipo do MR *"
                     options={MR.tipos}
                     value={MRtipo}
                     onChange={({ target }) => setMRtipo(target.value)}
                  />
                  <FieldGroup
                     as="select"
                     label="Etapa do funil *"
                     options={MR.etapa}
                     value={MRetapa}
                     onChange={({ target }) => setMRetapa(target.value)}
                     emptyOption={"N/A"}
                  />
               </>
            )}

            {isRefactorOn && (
               <FieldGroup
                  as="input"
                  label="ID da subtarefa que gerou o ajuste (se for falha na gestão do projeto, colocar o ID da tarefa mãe) *"
                  value={id}
                  onChange={({ target }) => setId(target.value)}
               />
            )}

            {/* BI */}
            {entregavel === BI_INCONSISTENCIA_DADOS ? (
               <FieldGroup
                  as="select"
                  label="Inconsistência identificada *"
                  options={BI_INCONSISTENCIA_DADOS_OPTIONS}
                  value={incosistenciaDados}
                  onChange={({ target }) => setIncosistenciaDados(target.value)}
               />
            ) : (
               <FieldGroup
                  as="input"
                  label="Nome do Material *"
                  value={nomeMaterial}
                  onChange={({ target }) => setNomeMaterial(target.value)}
                  onBlur={inputValidity}
               />
            )}
         </Form>

         <ResultLabel>
            {entregavel !== "MR"
               ? `${ajustes !== "Não se aplica" ? `[${ajustes}] | ` : ""}[${entregavel}] | [${funcaoResponsavel}${
                    qaFuncao !== "n/d" ? ` ${qaFuncao}` : ""
                 }] | ${isRefactorOn ? `[${id}] | ` : ""}${
                    entregavel === BI_INCONSISTENCIA_DADOS ? incosistenciaDados : nomeMaterial
                 }`
               : `${
                    ajustes !== "Não se aplica" ? `[${ajustes}] | ` : ""
                 }[${entregavel} ${MRtamanho} ${MRtipo}] | [${funcaoResponsavel}] | ${
                    isRefactorOn ? `[${id}] | ` : ""
                 }${MRetapa ? `${MRetapa} -` : ""} ${nomeMaterial}`}
         </ResultLabel>
      </section>
   )
}

export default Subtarefa
