import styled from "styled-components"

export const Container = styled.div`
   position: fixed;
   inset: 0;

   display: grid;
   place-items: center;

   background-color: ${({ theme }) => theme.darkBlue};

   z-index: 999999999999;

   > div {
      border: 1px solid #fff;
      background-color: #fff;
      color: #111;
      padding: 1.5rem;

      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;
      box-shadow: 0 5px 15px rgb(0 0 0 / 50%);

      -webkit-animation: swing-in-top-fwd 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
      animation: swing-in-top-fwd 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275) both;

      img {
         max-width: 10rem;
      }
   }

   p {
      font-size: 1.25rem;
      text-align: center;
   }

   button {
      outline: none;
      border: none;
      background-color: ${({ theme }) => theme.red};
      color: ${({ theme }) => theme.white};
      font-size: 1rem;
      padding: 0.25rem 0.25rem;
      cursor: pointer;
      font-weight: 400;

      padding-right: 1.25rem;

      display: flex;
      align-items: center;
      gap: 1.25rem;
      transition: filter 300ms ease;
      border-radius: 5px;

      > div {
         font-size: 1.5rem;
         background-color: ${({ theme }) => theme.white};
         display: grid;
         place-items: center;
         padding: 0.75rem;
         border-radius: 5px;
      }

      &:focus,
      &:hover {
         filter: brightness(1.15);
      }
   }

   form {
      display: flex;
      input {
         padding: 0.25rem;
      }

      button {
         display: grid;
         place-items: center;
         text-align: center;
         padding: 0.25rem;
      }
   }

   /**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
   @-webkit-keyframes swing-in-top-fwd {
      0% {
         -webkit-transform: rotateX(-100deg);
         transform: rotateX(-100deg);
         -webkit-transform-origin: top;
         transform-origin: top;
         opacity: 0;
      }
      100% {
         -webkit-transform: rotateX(0deg);
         transform: rotateX(0deg);
         -webkit-transform-origin: top;
         transform-origin: top;
         opacity: 1;
      }
   }
   @keyframes swing-in-top-fwd {
      0% {
         -webkit-transform: rotateX(-100deg);
         transform: rotateX(-100deg);
         -webkit-transform-origin: top;
         transform-origin: top;
         opacity: 0;
      }
      100% {
         -webkit-transform: rotateX(0deg);
         transform: rotateX(0deg);
         -webkit-transform-origin: top;
         transform-origin: top;
         opacity: 1;
      }
   }
`
