const firebaseConfig = {
    apiKey: "AIzaSyD4Mgtg_OFBB-RbBq_7Ddx6i0GYuBo8e8A",
    authDomain: "nomenclaturas-bowe-82b61.firebaseapp.com",
    projectId: "nomenclaturas-bowe-82b61",
    storageBucket: "nomenclaturas-bowe-82b61.firebasestorage.app",
    messagingSenderId: "158063058233",
    appId: "1:158063058233:web:1ec382ab0be6ae9354dce8"
  };

export default firebaseConfig;
