import { createGlobalStyle } from "styled-components"

export const ResetCSS = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
padding: 0;
}


ul {
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

a {
    text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
option,
select {
  font-size: 1rem;
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

`
