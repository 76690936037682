import styled from "styled-components"

export const Container = styled.div`
   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
   margin-top: ${(props) => (props.mt ? props.mt : "2rem")};
   background-color: ${({ theme }) => theme.white};
   color: ${({ theme }) => theme.black};

   display: grid;
   grid-template-columns: 1fr max-content;
   align-items: stretch;

   border-radius: 6px;
   min-height: 3rem;

   position: relative;
   overflow: hidden;

   p,
   h6 {
      word-wrap: break-word;
      word-break: break-all;
      align-self: center;

      font-weight: 700;
      font-size: 1.125rem;
      padding: 0.5rem 1em;
   }
`
