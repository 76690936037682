import React, { useEffect, useRef, useState } from "react"
import { Container } from "./styles"
import { HiOutlineArrowCircleDown } from "react-icons/hi"

export const UTMMidiaCard = ({ children, client, date, url, urlParam, identificador, handleDeleteEntry }) => {
   const [isOpen, setIsOpen] = useState(false)
   const [height, setHeight] = useState(0)

   const handleOpenner = () => {
      setIsOpen((prev) => !prev)
   }

   const mainRef = useRef()

   useEffect(() => {
      if (mainRef.current) {
         setHeight(mainRef.current.scrollHeight)
      }
   }, [mainRef])

   return (
      <Container listHeight={height} active={isOpen}>
         <header>
            <div>
               <h3>{client}</h3>
               <div>
                  <p>{date}</p>
                  <button onClick={handleOpenner}>
                     <HiOutlineArrowCircleDown />
                  </button>
               </div>
            </div>
            <p>
               <strong>URL:</strong> {url}
            </p>
         </header>
         <main ref={mainRef}>
            <h3 className="url_param">{urlParam}</h3>
            {children}

            {handleDeleteEntry && (
               <div className="button-container">
                  <button onClick={(e) => handleDeleteEntry(identificador)}>Deletar</button>
               </div>
            )}
         </main>
      </Container>
   )
}
