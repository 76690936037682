import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./styles/animations.css"

import { ThemeProvider } from "styled-components"
import { ResetCSS, GlobalCSS, theme } from "./styles"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
   <ThemeProvider theme={theme}>
      <ResetCSS />
      <GlobalCSS />

      <App />
   </ThemeProvider>
)
