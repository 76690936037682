export const FUNIL = ["tofu_mr", "tofu_aw", "mofu_aw", "mofu_mr", "bofu_mr", "bofu_hr"]

export const REGION = ["geral", "BR", "LAS"]

export const CLIENTS = [
   "ADOBE ENTERPRISE",
   "ADOBE SMB",
   "ADOBE 1:F",
   "AEC",
   "BANCO SOFISA",
   "BOAVISTA",
   "BOSCH",
   "BOWE",
   "EDENRED",
   "GREEN4T",
   "CPD",
   "HARPOON",
   "HELTE",
   "ILEGRA",
   "INTEGRATE",
   "ITS",
   "LENOVO",
   "MOTOROLA",
   "OMID",
   "PARS",
   "RIVERSOFT",
   "RHOPEN",
   "TRENDMICRO",
   "SEIDOR",
   "SEMANTIX",
   "SKIE",
   "UNIMED",
   "ZOOP",
]

export const DATA = {
   AEC: {
      produto: ["abm", "company_page", "geral"],
   },
   BOWE: {
      produto: ["abm", "dg"],
   },
   "BANCO SOFISA": {
      produto: ["geral"],
   },
   EDENRED: {
      produto: ["Commuter", "LSA"],
   },
   HARPOON: {
      produto: ["geral"],
   },

   HELTE: {
      produto: ["geral"],
   },

   "ADOBE SMB": {
      produto: ["acrobatsign", "acrobatpro", "creative-cloud", "cct", "geral"],
   },
   "ADOBE ENTERPRISE": {
      produto: ["acrobatsign", "acrobatpro", "geral"],
   },
   "ADOBE 1:F": {
      produto: ["acrobatsign", "acrobatpro", "geral"],
   },
   TRENDMICRO: {
      produto: ["cloudone", "geral"],
   },

   UNIMED: {
      produto: ["geral"],
   },
   CPD: {
      produto: ["waap", "guardicore", "zerotrust", "app", "geral"],
   },

   ITS: {
      produto: ["4hana", "onepage", "geral"],
   },

   INTEGRATE: {
      produto: ["b1", "whitepaper-industria-4-0", "geral"],
   },

   RIVERSOFT: {
      produto: ["geral"],
   },

   RHOPEN: {
      produto: ["geral"],
   },

   LENOVO: {
      produto: [
         "AISOLUTIONS",
         "AWARENESS",
         "AMD",
         "CANAIS",
         "CIO_PLAYBOOK",
         "EDGE",
         "HCI",
         "HPC",
         "HX",
         "HYBRIDCLOUD",
         "MX",
         "NVIDIA",
         "ONELENOVO",
         "OUTROS",
         "REDHAT",
         "SERVIDORES",
         "STORAGE",
         "TRUSCALE",
         "VX",
         "HYBRIDAI",
      ],
      regiao: ["BR", "LAS", "MEX", "NOLA", "SOLA"],
   },

   BOSCH: {
      produto: ["sdp", "sdp_es", "assessment", "institucional", "concorrentes", "open_finance", "tooling_monitoring"],
   },
   GREEN4T: {
      produto: ["IOT", "Data_Center", "awareness"],
   },
   SEIDOR: {
      produto: ["comerzzia", "exacta", "innovation", "retail", "seidor_up"],
   },
   SEMANTIX: {
      produto: ["sdi", "sdp", "sdp_es", "assessment", "institucional", "concorrentes", "open_finance", "linkapi"],
   },
   SKIE: {
      produto: ["geral"],
   },
   ILEGRA: {
      produto: ["products", "DDI", "devops", "DDI+Devops"],
   },

   PARS: {
      produto: ["adobesign", "adobepro"],
   },
   OMID: {
      produto: ["geral"],
   },

   ZOOP: {
      produto: ["pix", "payments", "banking", "fintechs", "alltechs", "novo_varejo", "branding"],
   },
   BOAVISTA: {
      produto: ["e-card", "e-marketplace"],
   },
   MOTOROLA: {
      produto: ["E22", "Thinkphone", "G53", "Familia"],
   },
}
