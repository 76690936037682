import { Container } from "./style"
import Copy from "./Copy"
import DA from "./DA"
import Digital from "./Digital"
import Planejamento from "./Planejamento"
import ABM from "./ABM"
import Financeiro from "./Financeiro"
import IC from "./IC"

export function ArquivosDriveContainer() {
   return (
      <Container>
         <ABM />
         <Copy />
         <DA />
         <Digital />
         <Financeiro />
         <IC />
         <Planejamento />
      </Container>
   )
}
