import React, { useEffect, useState } from "react"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import { inputValidity } from "../../../helpers/inputValidity"
import { useData } from "../../../../../hooks/useData"

import FieldGroup from "../../../../../components/FieldGroup"

const Financeiro = () => {
   const { data } = useData()

   const allTypes = data.drive.financeiro.tipo

   const allTypesKeys = allTypes.map((item) => {
      return Object.keys(item)[0]
   })

   const dataToDisplay = {
      _tipo: allTypesKeys,
   }

   const [tipo, setTipo] = useState(dataToDisplay._tipo[0])
   const [tipoABV, setTipoABV] = useState("")

   const [numero, setNumero] = useState("")

   const [competencia, setCompetencia] = useState("")
   const [competenciaABV, setCompetenciaABV] = useState("")

   const [emissor, setEmissor] = useState("")

   const [valor, setValor] = useState("")
   const [valorABV, setValorABV] = useState("")

   useEffect(() => {
      const abv = allTypes.find((type) => type[tipo])
      setTipoABV(Object.values(abv)[0])
   }, [allTypes, tipo])

   function transformData(data) {
      return data.replace(/\//g, "")
   }

   function transformValue(valor) {
      const valorFormatado = valor.toString().replace(",", ".")
      return valorFormatado
   }

   return (
      <section id="financeiro">
         <SectionTitle>Financeiro</SectionTitle>

         <Form>
            <FieldGroup
               as="select"
               label="Tipo *"
               onChange={({ target }) => setTipo(target.value)}
               value={tipo}
               options={dataToDisplay._tipo}
            />

            <FieldGroup as="input" label="Número" value={numero} onChange={({ target }) => setNumero(target.value)} />

            <FieldGroup
               as="input"
               label="Data de pagamento (MMAAAA)"
               value={competencia}
               onChange={({ target }) => setCompetencia(target.value)}
               onBlur={(event) => {
                  inputValidity(event)
                  setCompetenciaABV(transformData(competencia))
               }}
            />

            <FieldGroup
               as="input"
               label="Emissor *"
               value={emissor}
               onChange={({ target }) => setEmissor(target.value)}
               onBlur={inputValidity}
            />

            <FieldGroup
               as="input"
               label="Valor (R$) *"
               value={valor}
               onChange={({ target }) => setValor(target.value)}
               onBlur={(event) => {
                  inputValidity(event)
                  setValorABV(transformValue(valor))
               }}
            />
         </Form>

         <ResultLabel>
            {tipo === "Boleto"
               ? `${tipoABV}-${emissor ? `${emissor}-` : ""}${Number(valorABV).toFixed(2)}-${competencia}`
               : `${tipoABV}-${numero ? `${numero}` : `${competenciaABV}`}-${emissor ? `${emissor}-` : ""}${Number(
                    valorABV
                 ).toFixed(2)}-${competencia}`}
         </ResultLabel>
      </section>
   )
}

export default Financeiro
