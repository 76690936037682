import { useState } from "react"
import { inputValidity } from "../../../helpers/inputValidity"
import { useData } from "../../../../../hooks/useData"
import { currentYear, years_reduced_drive } from "../../../../../common/data"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import FieldGroup from "../../../../../components/FieldGroup"

const Planejamento = () => {
   const { data } = useData()

   const dataToDisplay = {
      _cliente: data.geral[0].clientes,
      _tipoMaterial: data.drive.planejamento.tipoDeMaterial,
      _flow: data.drive.tipoFlow,
      _periodo: data.geral[0].periodo,
      _etapaFunil: data.drive.etapaDoFunil,
      _nomeMaterial: "",
   }

   const [cliente, setCliente] = useState(dataToDisplay._cliente[0])
   const [tipoMaterial, setTipoMaterial] = useState(dataToDisplay._tipoMaterial[0])
   const [flow, setFlow] = useState(dataToDisplay._flow[0])
   const [periodo, setPeriodo] = useState(dataToDisplay._periodo[0])
   const [ano, setAno] = useState(currentYear)
   const [etapaFunil, setEtapaFunil] = useState(dataToDisplay._etapaFunil[0])
   const [nomeMaterial, setNomeMaterial] = useState("")

   return (
      <section id="planejamento">
         <SectionTitle>Planejamento</SectionTitle>

         <Form>
            <FieldGroup
               as="select"
               label="Cliente *"
               onChange={({ target }) => setCliente(target.value)}
               value={cliente}
               options={dataToDisplay._cliente}
            />

            <FieldGroup
               as="select"
               label="Tipo do Material *"
               onChange={({ target }) => setTipoMaterial(target.value)}
               value={tipoMaterial}
               options={dataToDisplay._tipoMaterial}
            />

            <FieldGroup
               as="select"
               label="Flow/Sprint/Headstart *"
               onChange={({ target }) => setFlow(target.value)}
               value={flow}
               options={dataToDisplay._flow}
            />

            <FieldGroup
               as="select"
               label="Período *"
               onChange={({ target }) => setPeriodo(target.value)}
               value={periodo}
               options={dataToDisplay._periodo}
            />

            <FieldGroup
               as="select"
               label="Ano *"
               onChange={({ target }) => setAno(target.value)}
               value={ano}
               options={years_reduced_drive}
            />

            <FieldGroup
               as="select"
               label="Etapa do Funil *"
               onChange={({ target }) => setEtapaFunil(target.value)}
               value={etapaFunil}
               options={dataToDisplay._etapaFunil}
               emptyOption="N/A"
            />

            <FieldGroup
               as="input"
               label="Nome do Material/Campanha *"
               onChange={({ target }) => setNomeMaterial(target.value)}
               value={nomeMaterial}
               onBlur={inputValidity}
            />
         </Form>

         <ResultLabel>
            {`${cliente} - ${tipoMaterial} - ${flow} - ${periodo} - ${ano} - ${
               etapaFunil ? `${etapaFunil} - ` : ""
            } ${nomeMaterial}`}
         </ResultLabel>
      </section>
   )
}

export default Planejamento
