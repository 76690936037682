import * as S from "./styles"
import {
   ArquivosDriveContainer,
   PlataformaAutomacaoContainer,
   RunrunitContainer,
} from "../../../../pages/Nomenclaturas/containers"

export const NowDisplaying = ({ display }) => {
   switch (display) {
      case "plataformas-automacao":
         return (
            <S.NowDisplayinggWrapper>
               <PlataformaAutomacaoContainer />
            </S.NowDisplayinggWrapper>
         )
      case "runrunit":
         return (
            <S.NowDisplayinggWrapper>
               <RunrunitContainer />
            </S.NowDisplayinggWrapper>
         )
      case "arquivos-drive":
         return (
            <S.NowDisplayinggWrapper>
               <ArquivosDriveContainer />
            </S.NowDisplayinggWrapper>
         )

      default:
   }
}
