import styled from "styled-components"

export const Container = styled.h1`
   max-width: max-content;
   text-align: center;
   margin-inline: auto;
   margin-bottom: 2.25rem;
   line-height: 1.2;
   font-size: clamp(1.75rem, 3vw, 2rem);
`
