import styled from "styled-components"

export const Container = styled.form`
   display: grid;
   gap: 1rem;

   width: 100%;
   margin-inline: auto;

   @media (min-width: 45em) {
      grid-template-columns: 1fr 1fr;

      .span-2 {
         grid-column: span 2;
      }
   }
`
