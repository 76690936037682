import { gql, GraphQLClient, request } from "graphql-request"

// FETCH ALL UTMS
export async function api_fetch_utm_midia_geral() {
   try {
      const response = await request(
         process.env.REACT_APP_API_KEY,
         `
            query UTM_MIDIA_GERAL {
                utmMidiaGoogles(orderBy:createdAt_DESC) {
                identificador
                cliente
                regiao
                funil
                data
                produto
                tipo
                utmterm
                grupoanuncio
                anuncio
                urlraw
                bNomecampanha
                bNomegrupoanuncio
                bNomeanuncio
                bUtmcontent
                bUtmsource
                bUtmmedium
                bUtmterm
                bUrlparam
                },
                
                utmMidiaFacebooks(orderBy:createdAt_DESC) {
                identificador
                cliente
                regiao
                funil
                data
                produto
                plataforma
                objetivo
                grupoanuncio
                anuncio
                urlraw
                bNomecampanha
                bNomegrupoanuncio
                bNomeanuncio
                bUtmsource
                bUtmmedium
                bUrlparam
                },
                
                utmMidiaLinkedins(orderBy:createdAt_DESC) {
                identificador
                cliente
                regiao
                funil
                data
                produto
                grupocampanha
                nomecampanha
                anuncio
                urlraw
                bNomegrupocampanha
                bNomecampanha
                bNomeanuncio
                bUtmsource
                bUtmmedium
                bUrlparam
                }
                
            }          
            `
      )

      if (response) {
         return response
      } else {
         return
      }
   } catch (err) {
      throw new Error("Something went wrong...")
   }
}

// POST UTM_GOOGLE
export async function api_post_utm_midia_google(obj) {
   const endPoint = process.env.REACT_APP_API_KEY

   const graphQLClient = new GraphQLClient(endPoint)

   const mutation = gql`
      mutation CREATE_UTM_MIDIA_GOOGLE(
         $identificador: String!
         $cliente: String!
         $regiao: String!
         $funil: String!
         $data: String!
         $produto: String!
         $tipo: String!
         $utmterm: String!
         $grupoanuncio: String!
         $anuncio: String!
         $urlraw: String!
         $bNomecampanha: String!
         $bNomegrupoanuncio: String!
         $bNomeanuncio: String!
         $bUtmcontent: String!
         $bUtmsource: String!
         $bUtmmedium: String!
         $bUtmterm: String!
         $bUrlparam: String!
      ) {
         createUtmMidiaGoogle(
            data: {
               identificador: $identificador
               cliente: $cliente
               regiao: $regiao
               funil: $funil
               data: $data
               produto: $produto
               tipo: $tipo
               utmterm: $utmterm
               grupoanuncio: $grupoanuncio
               anuncio: $anuncio
               urlraw: $urlraw
               bNomecampanha: $bNomecampanha
               bNomegrupoanuncio: $bNomegrupoanuncio
               bNomeanuncio: $bNomeanuncio
               bUtmcontent: $bUtmcontent
               bUtmsource: $bUtmsource
               bUtmmedium: $bUtmmedium
               bUtmterm: $bUtmterm
               bUrlparam: $bUrlparam
            }
         ) {
            identificador
            cliente
            regiao
            funil
            data
            produto
            tipo
            utmterm
            grupoanuncio
            anuncio
            urlraw
            bNomecampanha
            bNomegrupoanuncio
            bNomeanuncio
            bUtmcontent
            bUtmsource
            bUtmmedium
            bUtmterm
            bUrlparam
         }

         publishUtmMidiaGoogle(where: { identificador: $identificador }) {
            identificador
         }
      }
   `

   const variables = {
      identificador: obj.identificador,
      cliente: obj.cliente,
      regiao: obj.regiao,
      funil: obj.funil,
      data: obj.data,
      produto: obj.produto,
      tipo: obj.tipo,
      utmterm: obj.utmterm,
      grupoanuncio: obj.grupoanuncio,
      anuncio: obj.anuncio,
      urlraw: obj.urlraw,
      bNomecampanha: obj.bNomecampanha,
      bNomegrupoanuncio: obj.bNomegrupoanuncio,
      bNomeanuncio: obj.bNomeanuncio,
      bUtmcontent: obj.bUtmcontent,
      bUtmsource: obj.bUtmsource,
      bUtmmedium: obj.bUtmmedium,
      bUtmterm: obj.bUtmterm,
      bUrlparam: obj.bUrlparam,
   }

   await graphQLClient.request(mutation, variables)
}

// POST UTM_FACEBOOK
export async function api_post_utm_midia_facebook(obj) {
   const endPoint = process.env.REACT_APP_API_KEY

   const graphQLClient = new GraphQLClient(endPoint)

   const mutation = gql`
      mutation CREATE_UTM_MIDIA_FACEBOOK(
         $identificador: String!
         $cliente: String!
         $regiao: String!
         $funil: String!
         $data: String!
         $produto: String!
         $plataforma: String!
         $objetivo: String!
         $grupoanuncio: String!
         $anuncio: String!
         $urlraw: String!
         $bNomecampanha: String!
         $bNomegrupoanuncio: String!
         $bNomeanuncio: String!
         $bUtmsource: String!
         $bUtmmedium: String!
         $bUrlparam: String!
      ) {
         createUtmMidiaFacebook(
            data: {
               identificador: $identificador
               cliente: $cliente
               regiao: $regiao
               funil: $funil
               data: $data
               produto: $produto
               plataforma: $plataforma
               objetivo: $objetivo
               grupoanuncio: $grupoanuncio
               anuncio: $anuncio
               urlraw: $urlraw
               bNomecampanha: $bNomecampanha
               bNomegrupoanuncio: $bNomegrupoanuncio
               bNomeanuncio: $bNomeanuncio
               bUtmsource: $bUtmsource
               bUtmmedium: $bUtmmedium
               bUrlparam: $bUrlparam
            }
         ) {
            identificador
            cliente
            regiao
            funil
            data
            produto
            plataforma
            objetivo
            grupoanuncio
            anuncio
            urlraw
            bNomecampanha
            bNomegrupoanuncio
            bNomeanuncio
            bUtmsource
            bUtmmedium
            bUrlparam
         }

         publishUtmMidiaFacebook(where: { identificador: $identificador }) {
            identificador
         }
      }
   `

   const variables = {
      identificador: obj.identificador,
      cliente: obj.cliente,
      regiao: obj.regiao,
      funil: obj.funil,
      data: obj.data,
      produto: obj.produto,
      plataforma: obj.plataforma,
      objetivo: obj.objetivo,
      grupoanuncio: obj.grupoanuncio,
      anuncio: obj.anuncio,
      urlraw: obj.urlraw,
      bNomecampanha: obj.bNomecampanha,
      bNomegrupoanuncio: obj.bNomegrupoanuncio,
      bNomeanuncio: obj.bNomeanuncio,
      bUtmsource: obj.bUtmsource,
      bUtmmedium: obj.bUtmmedium,
      bUrlparam: obj.bUrlparam,
   }

   await graphQLClient.request(mutation, variables)
}

// POST UTM_LINKEDIN
export async function api_post_utm_midia_linkedin(obj) {
   const endPoint = process.env.REACT_APP_API_KEY

   const graphQLClient = new GraphQLClient(endPoint)

   const mutation = gql`
      mutation CREATE_UTM_MIDIA_LINKEDIN(
         $identificador: String!
         $cliente: String!
         $regiao: String!
         $funil: String!
         $data: String!
         $produto: String!
         $grupocampanha: String!
         $nomecampanha: String!
         $anuncio: String!
         $urlraw: String!
         $bNomegrupocampanha: String!
         $bNomecampanha: String!
         $bNomeanuncio: String!
         $bUtmsource: String!
         $bUtmmedium: String!
         $bUrlparam: String!
      ) {
         createUtmMidiaLinkedin(
            data: {
               identificador: $identificador
               cliente: $cliente
               regiao: $regiao
               funil: $funil
               data: $data
               produto: $produto
               grupocampanha: $grupocampanha
               nomecampanha: $nomecampanha
               anuncio: $anuncio
               urlraw: $urlraw
               bNomegrupocampanha: $bNomegrupocampanha
               bNomecampanha: $bNomecampanha
               bNomeanuncio: $bNomeanuncio
               bUtmsource: $bUtmsource
               bUtmmedium: $bUtmmedium
               bUrlparam: $bUrlparam
            }
         ) {
            identificador
            cliente
            regiao
            funil
            data
            produto
            grupocampanha
            nomecampanha
            anuncio
            urlraw
            bNomegrupocampanha
            bNomecampanha
            bNomeanuncio
            bUtmsource
            bUtmmedium
            bUrlparam
         }

         publishUtmMidiaLinkedin(where: { identificador: $identificador }) {
            identificador
         }
      }
   `

   const variables = {
      identificador: obj.identificador,
      cliente: obj.cliente,
      regiao: obj.regiao,
      funil: obj.funil,
      data: obj.data,
      produto: obj.produto,
      grupocampanha: obj.grupocampanha,
      nomecampanha: obj.nomecampanha,
      anuncio: obj.anuncio,
      urlraw: obj.urlraw,
      bNomegrupocampanha: obj.bNomegrupocampanha,
      bNomecampanha: obj.bNomecampanha,
      bNomeanuncio: obj.bNomeanuncio,
      bUtmsource: obj.bUtmsource,
      bUtmmedium: obj.bUtmmedium,
      bUrlparam: obj.bUrlparam,
   }

   await graphQLClient.request(mutation, variables)
}

// DELETE UTM_GOOGLE
export async function api_delete_utm_midia_google(id) {
   const endPoint = process.env.REACT_APP_API_KEY

   const graphQLClient = new GraphQLClient(endPoint)

   try {
      const mutation = gql`
         mutation DELETE_UTM_MIDIA_GOOGL($identificador: String!) {
            deleteUtmMidiaGoogle(where: { identificador: $identificador }) {
               identificador
            }
         }
      `

      const variables = {
         identificador: id,
      }

      await graphQLClient.request(mutation, variables)
   } catch (err) {
      throw err
   }
}

// DELETE UTM_FACEBOOK
export async function api_delete_utm_midia_facebook(id) {
   const endPoint = process.env.REACT_APP_API_KEY

   const graphQLClient = new GraphQLClient(endPoint)

   try {
      const mutation = gql`
         mutation DELETE_UTM_MIDIA_FACEBOOK($identificador: String!) {
            deleteUtmMidiaFacebook(where: { identificador: $identificador }) {
               identificador
            }
         }
      `

      const variables = {
         identificador: id,
      }

      await graphQLClient.request(mutation, variables)
   } catch (err) {
      throw err
   }
}

// DELETE UTM_LINKEDIN
export async function api_delete_utm_midia_linkedin(id) {
   const endPoint = process.env.REACT_APP_API_KEY

   const graphQLClient = new GraphQLClient(endPoint)

   try {
      const mutation = gql`
         mutation DELETE_UTM_MIDIA_LINKEDIN($identificador: String!) {
            deleteUtmMidiaLinkedin(where: { identificador: $identificador }) {
               identificador
            }
         }
      `

      const variables = {
         identificador: id,
      }

      await graphQLClient.request(mutation, variables)
   } catch (err) {
      throw err
   }
}
